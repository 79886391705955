<template>
  <div class="hamburger-menu">
    <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
      <div class="img-mobile-container"><img src="@/assets/images/Menu-icon-mobile.svg" alt="hamburger"></div>
    </button>
    <ul class="hamburger-menu__list" :class="{ 'is-active': isActive }">
      <button @click="isActive = !isActive" class="hamburger-menu__close-button" aria-label="Cerrar menú"><span class="material-symbols-rounded">cancel</span></button>
      <li class="hamburger-menu__item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
        <router-link :to="route.route">{{ route.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      isActive: false,
      routes: [
        {
          name       :  'Sportsbook',
          route      :  'sportsbook'
        },
        {
          name       :  'Racebook',
          route      :  'racebook'
        },
        {
          name       :  'Live Betting',
          route      :  'live-betting'
        },
        {
          name       :  'Casino',
          route      :  'casino'
        },
      ]
    };

  },
};
</script>
