<template>
  <div id="go-up" class="go-up">anchor</div>
  <brand-header></brand-header>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <a href="/">
          <img class="header-content__img" src="@/assets/images/logo.png" alt="sportsguyz" />
        </a>
      </div>
      <div class="header-content__info">
        <ul class="header-content__info--list">
          <li class="header-content__info--list-item">
            <router-link to="sportsbook">SportsBook</router-link>
          </li>
          <li class="header-content__info--list-item">
            <router-link to="live-betting">Live Betting</router-link>
          </li>
          <li class="header-content__info--list-item">
            <router-link to="casino">Casino</router-link>
          </li>
          <li class="header-content__info--list-item">
            <router-link to="racebook">Racebook</router-link>
          </li>
        </ul>
      </div>
      <div class="header-content__cta">
        <!-- <span class="header-content__language material-symbols-rounded">language</span> -->
        <div class="header-buttons">
          <form class="form-login form-desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input type="text" placeholder="User" name="account" id="account">
            <input type="password" placeholder="Passworld" name="password" id="password">
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">LOGIN</button>
          </form>
          <form class="form-login form-mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input type="text" placeholder="User" name="account" id="account">
            <input type="password" placeholder="Passworld" name="password" id="password">
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">LOGIN</button>
          </form>
            <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal">BETSLIP</a>
        </div>
      </div>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
  import ModalComponent from '@/components/modal.vue'
  import BrandHeader from '@/components/brand-header.vue'
  export default {
    name: "HeaderComponent",
    components: {
      ModalComponent,
      BrandHeader
    },
    methods: {
      openModal() {
        this.$refs.modal.openModal();
      }
    },
    setup() {
      const DGS_SITEID = 219;
      const backendUrl = "sportsguyz.com";
      return {
        DGS_SITEID,
        backendUrl,
      }
    },
  };
</script>
